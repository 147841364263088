<template>
  <div class="page">
    <div class="section-search">
      <div class="search-flex">
        <div class="flex-left">
          <img @click="back" src="./img/clear.png" alt="" />
        </div>
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <div class="input-current">
                <div class="circle"></div>
                <v-input
                  ref="curInput"
                  v-model="form.curStart"
                  :maxlength="20"
                  placeholder="请输入当前位置"
                  @focus="curFocus"
                  @blur="curBlur"
                  @change="curChange"
                />
              </div>
              <div class="line"></div>
              <div class="input-arrive">
                <div class="circle"></div>
                <v-input
                  ref="arriveInput"
                  v-model="form.arrowEnd"
                  :maxlength="20"
                  placeholder="请输入当前位置"
                  @focus="arriveFocus"
                  @blur="arriveBlur"
                  @change="arriveChange"
                />
              </div>
            </div>
            <div class="input-icon" v-show="isShowIcon" @click="toPlan">
              <img src="./img/go_icon.png" alt="" />
            </div>
          </div>
          <!-- <div class="right-tabs">
            <div
              :class="[
                'right-tabs--tab',
                item.value === curTab.value ? 'selectTab' : '',
              ]"
              v-for="(item, index) in tabs"
              :key="index"
              @click="chooseTab(item)"
            >
              <span>{{ item.text }}</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="search-options" v-if="listData.length > 0">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="search-options--option"
            v-for="(option, oIndex) in listData"
            :key="oIndex"
            @click="chooseOption(option)"
          >
            <div class="image"><img src="./img/search_icon.png" alt="" /></div>
            <span>{{ option.name }}</span>
          </div>
          <!-- <div class=""></div> -->
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { dataListByNameURL } from "./api";

export default {
  data() {
    return {
      // isInput: true, // 是否输入框输入状态
      // curTab: {
      //   text: "地下路径",
      //   value: 1,
      // },
      // tabs: [
      //   {
      //     text: "地下路径",
      //     value: 1,
      //   },
      //   {
      //     text: "地面路径",
      //     value: 2,
      //   },
      // ],
      isOption: false, // 是点击选项
      isShowIcon: false,
      listData: [],
      focus: undefined,
      curTab: {},
      curForm: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      timer: null,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 20,
    };
  },
  watch: {
    form: {
      handler(val) {
        const { curStart, arrowEnd } = val;
        this.isShowIcon = curStart && arrowEnd;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    let { form, focus, curTab } = this.$route.query;
    if (form && focus && curTab) {
      this.form = JSON.parse(form);
      this.curForm = JSON.parse(form);
      this.curTab = JSON.parse(curTab);
      this.focus = focus;
    }
    this.$nextTick(() => {
      if (this.focus === "1") {
        this.$refs.curInput.focus();
      } else {
        this.$refs.arriveInput.focus();
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "routePlanPlanning") {
        vm.$router.replace({
          name: "routePlan",
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem(
      "routePlanQuery",
      JSON.stringify({
        form: this.form,
        curTab: this.curTab,
      })
    );
    next();
  },
  methods: {
    back() {
      // this.$router.go(-2);
      this.isOption = true;
      this.form = {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      };
      // this.$router.go(-1);
      this.$router.replace({
        name: "routePlan",
      });
    },
    // 选择地上地下
    // chooseTab(item) {
    //   this.curTab = item;
    // },
    //防抖
    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, wait);
    },
    // 当前位置
    curFocus() {
      this.focus = "1";
      this.timer = null;
      this.onRefresh();
    },
    curBlur() {
      // const timer = setTimeout(() => {
      //   // this.form.arrowEnd = this.curForm.curStart;
      //   const data = this.listData.filter((v) => v.name === this.form.curStart);
      //   if (data.length > 0) {
      //     this.form.start = data[0].id;
      //   } else {
      //     this.form.start = undefined;
      //   }
      //   clearTimeout(timer);
      // }, 100);
    },
    // 当前位置-数据变化
    curChange() {
      this.debounce(this.onRefresh, 500);
    },
    arriveFocus() {
      this.focus = "2";
      this.timer = null;
      this.onRefresh();
    },
    arriveBlur() {
      // const timer = setTimeout(() => {
      //   // this.form.arrowEnd = this.curForm.arrowEnd;
      //   const data = this.listData.filter((v) => v.name === this.form.arrowEnd);
      //   if (data.length > 0) {
      //     this.form.end = data[0].id;
      //   } else {
      //     this.form.end = undefined;
      //   }
      //   clearTimeout(timer);
      // }, 100);
    },
    arriveChange() {
      this.debounce(this.onRefresh, 500);
    },

    // 选择位置选项
    chooseOption(item) {
      this.isOption = true;
      if (this.focus === "1") {
        this.form.start = item.id;
        this.form.curStart = item.name;
        this.curForm.start = item.id;
        this.curForm.curStart = item.name;
      } else {
        this.form.end = item.id;
        this.form.arrowEnd = item.name;
        this.curForm.end = item.id;
        this.curForm.arrowEnd = item.name;
      }
      // this.$router.go(-1);
      this.$router.replace({
        name: "routePlan",
      });
    },
    vaild() {
      if (!this.form.start) {
        this.$toast({ message: "请输入正确的当前位置", duration: 1500 });
        return;
      } else if (!this.form.end) {
        this.$toast({ message: "请输入正确的到达位置", duration: 1500 });
        return;
      } else if (this.form.start === this.form.end) {
        this.$toast({ message: "起点等于终点，无需导航", duration: 1500 });
        return;
      }
      return true;
    },
    // 前往路线规划页面
    toPlan() {
      let result = this.vaild();
      if (result) {
        this.$router.push({
          name: "routePlanPlanning",
          query: {
            form: JSON.stringify(this.form),
            curTab: JSON.stringify(this.curTab),
            focus: this.focus,
          },
        });
      }
    },

    // 获取用户信息
    // getUserInfo() {
    //   if (!this.userId || this.userId == "0") {
    //     this.onLoad();
    //     return;
    //   }
    //   let params = {
    //     userId: this.userId,
    //   };
    //   this.$axios
    //     .get(userInfoURL, {
    //       params,
    //     })
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.userInfo = res.data;
    //         this.phone = this.userInfo.mobile;
    //       }
    //     })
    //     .finally(() => {
    //       this.onLoad();
    //     });
    // },

    onRefresh() {
      if (this.isOption) {
        this.isOption = false;
        return;
      }
      /* 请求接口前清空当前、到达的id */
      if (this.focus === "1") {
        this.form.start = undefined;
      } else {
        this.form.end = undefined;
      }
      /* end */
      this.finished = true;
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(dataListByNameURL, {
          params: {
            name: this.focus === "1" ? this.form.curStart : this.form.arrowEnd,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }

              /* 请求成功后，判断 */
              const keyName = this.focus === "1" ? "curStart" : "arrowEnd";
              const keyId = this.focus === "1" ? "start" : "end";
              const data = this.listData.filter(
                (v) => v.name === this.form[keyName]
              );
              if (data.length > 0) {
                this.form[keyId] = data[0].id;
              } else {
                this.form[keyId] = undefined;
              }
              /* end */
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  background: url("./img/background.png") no-repeat left top;
  background-size: 100% 484px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-search {
    box-sizing: border-box;
    width: 100%;
    //  height: 484px;
    padding: 126px 30px 64px;
    .search-flex {
      display: flex;
      width: 100%;
      // height: 100%;
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      .flex-left {
        margin-right: 48px;
        img {
          // height: 26px;
          // width: 14px;
          height: 24px;
          width: 24px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f9f9f9;
          width: 100%;
          height: 160px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .input-current,
            .input-arrive {
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 64px;
              .circle {
                margin: 0 12px 0 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #fff;
                  border-radius: 50%;
                  border: 6px solid #68cfa8;
                }
              }
              ::v-deep .van-cell {
                width: 356px;
                background-color: transparent;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .line {
              margin: 0 18px 0 50px;
              width: 420px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                &::before {
                  border: 6px solid #fa6400;
                }
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
              vertical-align: middle;
            }
          }
        }
        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            border-radius: 10px;
            padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-options {
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      padding: 0px 22px 22px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      .search-options--option {
        display: flex;
        padding: 30px 12px;
        border-bottom: 2px solid #eeeeee;
        line-height: 40px;
        .image {
          padding-top: 4px;
          img {
            width: 28px;
            height: 28x;
            margin: 0 18px 0 0;
          }
        }
        span {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
